<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>Tgame365베팅내역</sub-title>
                <div class="sports_links">
                    <router-link tag="button" to="/sports_betinfo" class="bw3"> 스포츠</router-link>
                    <router-link class="bw3 " to="/leisure_betinfo" tag="button">미니게임</router-link>
                    <router-link class="bw3 " to="/thirdpartgame_betinfo" tag="button">Tgame365</router-link>
                </div>
                <div class="button_group">
                    <button class="bw3" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_HILOW)">하이로우</button>
                    <button class="bw3" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_RUOLLET)">룰렛</button>
                    <button class="bw3" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_BACCARAT)">바카라</button>
<!--                    <button class="bw3" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_CASINO)">카지노</button>-->
<!--                    <button class="bw3" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_SLOT)">슬롯</button>-->
<!--                    <button class="bw3 mt2" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_FW)">포츈휠</button>-->
<!--                    <button class="bw3 mt2" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_SNAIL3)">달팽이 3줄</button>-->
<!--                    <button class="bw3 mt2" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_SNAIL4)">달팽이 4줄</button>-->
                </div>
                <div style="margin: 10px 3px">
                    <button @click="deleteAll" style="padding: 10px 20px;background-color: red;border-radius: 3px">전체삭제</button>
                </div>
                <div class="board_list">
                    <table class="table">
                        <tr>
                            <th style="width: 10%">TSID</th>
                            <th style="width: 10%">타입</th>
                            <th style="width: 5%">G결과</th>
                            <th style="width: 8%">베팅금</th>
                            <th style="width: 8%">당첨금</th>
                            <th style="width: 5%">베팅결과</th>
                            <th style="width: 5%">시간</th>
                            <th style="width: 5%">-</th>
                        </tr>
                        <tr  v-for="(item,idx) in resultList" :key="idx">
                            <td>
                                {{item.transactionid}}
                            </td>
                            <td>{{item.gtype}}</td>
                            <td>{{item.gameResult}}</td>
                            <td>
                                {{item.amount | comma}}
                            </td>
                            <td>
                                {{item.winamount | comma}}
                            </td>
                            <td>
                                <span v-if="item.betResult === 1" class="text-skyblue">당첨</span>
                                <span v-if="item.betResult === 2" class="">x</span>
                            </td>
                            <td>{{item.createTime|datef('MM-DD HH:mm')}}</td>
                            <td style="color: red;cursor: pointer" @click="deleteById(item.id)">삭제</td>
                        </tr>
                    </table>
                </div>
                <pagination :page-index=" pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"></pagination>
            </div>
        </div>

        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>
    </div>

</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import SubTitle from "../../components/SubTitle";
    import leisureConst from "../../common/leisureConst";
    import sportsConst from "../../common/sportsConst";
    import {
        deleteLeisuerAll,
        deleteT365BetAll,
        deleteT365BetById,
        getThirdPartGameResult
    } from "../../network/leisureRequest";
    import Pagination from "../../components/pagenation/Pagination";
    import LeisureGameLinksComp from "../../components/leisuregame/LeisureGameLinksComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import GameResultLinks from "../../components/GameResultLinks";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import TopbarComp from "../../components/TopbarComp";


    export default {
        name: "ThirdPartGameBetInfo",
        components: {
            TopbarComp,
            UserInfoComp,
            RightButtonsComp,
            SportsBetCartComp,
            GameResultLinks,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp,
            ScrollTopComp, LeisureGameLinksComp, Pagination, SubTitle, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                leisureConst,
                sportsConst,
                pageNum: 1,
                pageSize: 20,
                total: 1,
                kind: leisureConst.LEISURE_KIND_TGAME365_HILOW,
                resultList: []
            }
        },
        methods: {
            deleteAll(){
                this.$swal({
                    title: '베팅내역 전체를 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteT365BetAll().then(res=>{
                            this.getThirdPartGameResult()
                        })
                    }
                });

            },
            deleteById(id){
                deleteT365BetById(id).then(res=>{
                    this.getThirdPartGameResult()
                })
            },
            changeType(kind){
               this.kind = kind
               this.getThirdPartGameResult()
            },
            getThirdPartGameResult() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getThirdPartGameResult(this.kind, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    if (res.data.success) {
                        this.total = res.data.total
                        this.resultList = res.data.data
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            pageChange(page) {
                this.pageNum = page
                this.getThirdPartGameResult()
            },
            leisureKindChanged(kind) {
                this.kind = kind;
                this.pageNum = 1
            }
        },
        created() {
            this.getThirdPartGameResult();
        },
        watch: {
            kind() {
                this.getThirdPartGameResult();
            }
        }
    }
</script>

<style scoped>
    .result p {
        display: inline-block;
        margin-right: 5px;
    }

    @media screen and (max-width: 800px) {
        .result p {
            display: block;
            margin-right: 0;
        }
    }
</style>